import { DayConverter, DaysConverter } from '../converter/dayConverter';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DatetimeConverter } from '../converter/datetime.converter';
import { Days } from '../days.model';
import { ExtendedFrequencyTypeConverter } from '../converter/extended-frequency-type.converter';
import { FrequencyOccurence } from '../frequency-occurrence.model';
import { FrequencyOccurenceConverter } from '../converter/frequency-occurrence.converter';
import { FrequencyTypeConverter } from '../converter/frequency-type.converter';
import { ModelAbstract } from '../model.abstract';

export enum FrequencyType {
  NONE = 'NONE',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  WEEKLY = 'WEEKLY',
  MONTHLY_DAY = 'MONTHLY_DAY',
  MONTHLY_DATE = 'MONTHLY_DATE',
  CUSTOM = 'CUSTOM',
}

export enum ExtendedFrequencyType {
  NONE = 'NONE',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY_DAY = 'MONTHLY_DAY',
  MONTHLY_DATE = 'MONTHLY_DATE',
  ONE_SHOT = 'ONE_SHOT',
  EVERY_X_DAYS = 'EVERY_X_DAYS',
  EVERY_X_WEEKS = 'EVERY_X_WEEKS',
  EVERY_X_MONTHS = 'EVERY_X_MONTHS',
}

@JsonObject('FrequencyPolicy')
export class FrequencyPolicy extends ModelAbstract {
  @JsonProperty('dayOfTheWeek', DayConverter, true)
  public dayOfTheWeek: Days | null = null;

  @JsonProperty('occurence', FrequencyOccurenceConverter, false)
  public occurence: FrequencyOccurence | null = null;

  @JsonProperty('period', Number, true)
  public period: number | null = 0;

  @JsonProperty('startTime', DatetimeConverter, true)
  public startTime: Date | null = new Date();

  @JsonProperty('type', FrequencyTypeConverter, true)
  public type: FrequencyType | null = FrequencyType.WEEKLY;
}

@JsonObject('ExtendedFrequencyPolicy')
export class ExtendedFrequencyPolicy extends ModelAbstract {
  @JsonProperty('daysOfTheWeek', DaysConverter, true)
  public daysOfTheWeek: Days[] = [];

  @JsonProperty('endDate', DatetimeConverter, true)
  public endDate: Date | null = null;

  @JsonProperty('endExecutionTime', String, true)
  public endExecutionTime: string | null = null;

  @JsonProperty('executionDate', DatetimeConverter, true)
  public executionDate: Date | null = null;

  @JsonProperty('executionDay', DayConverter, true)
  public executionDay: Days | null = null;

  @JsonProperty('executionTime', String, true)
  public executionTime: string | null = null;

  @JsonProperty('interval', Number, true)
  public interval: number = 0;

  @JsonProperty('occurency', FrequencyOccurenceConverter, true)
  public occurency: FrequencyOccurence | null = null;

  @JsonProperty('startDate', DatetimeConverter, true)
  public startDate: Date | null = null;

  @JsonProperty('startExecutionTime', String, true)
  public startExecutionTime: string | null = null;

  @JsonProperty('type', ExtendedFrequencyTypeConverter, true)
  public type: ExtendedFrequencyType = ExtendedFrequencyType.NONE;

  @JsonProperty('useDateFilter', Boolean, false)
  public useDateFilter: boolean = false;

  @JsonProperty('useDayFilter', Boolean, false)
  public useDayFilter: boolean = false;

  @JsonProperty('useTimeFilter', Boolean, false)
  public useTimeFilter: boolean = false;

  // @JsonProperty('monthInterval', Number, true)
  // public monthInterval: number = 0;
}
