import {
  Id,
  TemplateFull,
  TemplateFullAttributes,
  TemplateLight,
} from '@al/model';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import {
  TemplateFullQuery,
  TemplateFullStore,
  TemplateLightStore,
} from '@al/akita';
import { GenericService } from './generic.service';
import { ReferentielService } from '@al/referentiel';
import { SiteService } from './site.service';
import { TemplateRestService } from '@al/rest-services';

@Injectable({
  providedIn: 'root',
})
export class TemplateFullService
  extends GenericService<TemplateFull>
  implements OnDestroy
{
  private ngUnsubscribe = new Subject();

  public constructor(
    protected override restService: TemplateRestService,
    protected query: TemplateFullQuery,
    protected store: TemplateFullStore,
    protected templateLightStore: TemplateLightStore,
    protected siteService: SiteService
  ) {
    super(restService, query, store);
  }

  // public createNewTemplate(): void {
  //   var template = new TemplateFull();
  //   template.siteId = this.siteService.getCurrentSite()!.id;
  //   this.newTemplateSubject.next(template);
  // }

  public getFullTemplate(
    templateLight: TemplateLight
  ): Observable<TemplateFull> {
    return this.restService.getFullTemplate(templateLight.id).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: TemplateFull) => {
        // Pas de stockage dans le store le template devient immutable. On va partager le model par la reference
        return data;
      })
    );
  }

  public loadStore(): Observable<void> {
    return new Observable<void>();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public save(templateFull: TemplateFull): Observable<TemplateLight> {
    if (templateFull.id != 0) {
      return this.restService.save(templateFull).pipe(
        takeUntil(this.ngUnsubscribe),
        map((data: TemplateFullAttributes) => {
          let templateLight: TemplateLight = this.convertToTemplateLight(
            data.attributes
          );
          this.templateLightStore.replace(templateLight.id, templateLight);
          return templateLight;
        })
      );
    } else {
      templateFull.status = new ReferentielService().getDefaultTemplateStatus();
      return this.restService.create(templateFull).pipe(
        takeUntil(this.ngUnsubscribe),
        map((data: Id) => {
          let templateLight: TemplateLight =
            this.convertToTemplateLight(templateFull);
          templateLight.id = parseInt(data.id);
          this.templateLightStore.upsert(templateLight.id, templateLight);
          // this.templateLightStore.setActive(templateLight.id);
          return templateLight;
        })
      );
    }
  }

  private convertToTemplateLight(data: TemplateFull): TemplateLight {
    let templateLight = new TemplateLight();
    templateLight.id = data.id;
    templateLight.siteId = data.siteId;
    templateLight.changeDate = data.changeDate;
    templateLight.title = data.title;
    templateLight.visible = data.visible;
    templateLight.status = data.status;
    templateLight.category = data.category;
    templateLight.location = data.location;
    templateLight.frequency = data.frequency;
    templateLight.extendedFrequencyPolicy = data.extendedFrequencyPolicy;
    templateLight.lastInstanceValidation = data.lastInstanceValidation;

    return templateLight;
  }
}
