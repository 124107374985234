<div class="al-template-form" formGroupDirective>
  <div class="header">
    <h1 translate class="parent-component-title" *ngIf="this.creation">
      CREATION_TEMPLATE
    </h1>
    <h1 translate class="parent-component-title" *ngIf="!this.creation">
      UPDATE_TEMPLATE
    </h1>
    <span>
      <button
        *ngIf="!this.creation"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="go To History"
        type="button"
        (click)="goToHistory()"
      >
        <mat-icon>history</mat-icon>
      </button>

      <button mat-raised-button (click)="openBackDialog(backDialog)">
        <mat-icon class="back">keyboard_arrow_left</mat-icon>
        {{ "BACK" | translate }}
      </button>
    </span>
  </div>

  <mat-card>
    <al-template-widget-selector
      class="widget-selector"
    ></al-template-widget-selector>
    <div style="display: flex; flex-direction: column; flex-grow: 4">
      <al-template-title></al-template-title>
      <al-template-body class="template-body"></al-template-body>
    </div>
    <div class="template-right-pane">
      <!--      <al-template-body class="template-body"></al-template-body>-->

      <!--      <al-template-info class="template-info" [formGroupDirective]="formGroupDirective">-->

      <!--      </al-template-info>-->
      <div style="position: -webkit-sticky; position: sticky; top: 0px">
        <al-template-info class="template-info"></al-template-info>
        <al-template-frequency
          class="template-frequency"
        ></al-template-frequency>
        <al-template-cron class="template-frequency"></al-template-cron>

        <div class="buttons">
          <al-preview-component class="preview"></al-preview-component>
          <button
            class="button"
            mat-button
            [disabled]="!isValid()"
            (click)="onSubmit()"
          >
            <mat-icon>save</mat-icon>
            {{ "TEMPLATE_SAVE" | translate }}
          </button>
          <button mat-button *ngIf="this.isPrintable()" (click)="exportPdf()">
            <mat-icon
              floatLabel="always"
              appearance="outline"
              class="material-icons-outlined"
              >picture_as_pdf_outline
            </mat-icon>
            {{ "EXPORT_PDF_RONDE" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!--    <button (click)="reset()">reset</button>-->
  </mat-card>
</div>

<ng-template #infoDialog>
  <al-info-dialog (confirm)="doSomething()">
    <div title translate>{{ infoDialogTitle }}</div>
    <div content translate>{{ infoDialogMessage }}</div>
    <div confirm-button translate>OK</div>
  </al-info-dialog>
</ng-template>
<ng-template #cancelDialog>
  <al-confirm-dialog (confirm)="clean()">
    <div title translate>CANCEL</div>
    <div content translate>AGREE_TO_CANCEL</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #backDialog>
  <mat-dialog-content>
    <p translate>WARNING_LEAVING_PAGE</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">
      {{ "CANCEL" | translate }}
    </button>
    <button
      mat-button
      matDialogClose
      [disabled]="!isValid()"
      (click)="onSubmit(true)"
      color="primary"
    >
      {{ "SAVE_QUIT" | translate }}
    </button>
    <button mat-button matDialogClose (click)="back()" color="primary">
      {{ "QUIT" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<div style="display: none" translate>TEMPLATE_SAVED_SUCCESSFULLY</div>
<div style="display: none" translate>TEMPLATE_SAVED_SUCCESSFULLY_TITLE</div>
<div style="display: none" translate>TEMPLATE_SAVED_KO</div>
<div style="display: none" translate>TEMPLATE_SAVED_KO_TITLE</div>
<ng-template #pdfDialog>
  <h2 matDialogTitle translate>PDF_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>
<ng-template #exportPDFDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_PDF_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_PDF_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>
