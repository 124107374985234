export enum FormControlNames {
  title = 'title',
  id = 'id',
  category = 'category',
  location = 'location',
  visibility = 'visibility',
  date = 'Last round validation date',
  changeDate = 'Last modification date',
  frequencyPolicy = 'Frequency type',
  furnace = 'furnace',

  // text = 'text',
  // link = 'link',
}
