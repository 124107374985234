import {
  ExtendedFrequencyPolicy,
  FrequencyPolicy,
  Id,
  IdNumberList,
  PresignedUrl,
  Site,
  TemplateFull,
  TemplateFullAttributes,
  TemplateItems,
  TemplateLight,
} from '@al/model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { SiteService } from '@al/services';

@Injectable({
  providedIn: 'root',
})
export class TemplateRestService
  extends GenericRestService
  implements OnDestroy
{
  private currentSite: Site = new Site();

  private ngUnsubscribe = new Subject();

  public constructor(
    protected environmentService: EnvironmentService,
    protected siteService: SiteService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public copySite(
    template: TemplateLight,
    siteId: string
  ): Observable<TemplateLight> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('siteId', siteId);

    return super.post<TemplateLight>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.duplicate}${template.id}`,
      httpParams,
      this.getTemplateFullItemClazz(),
      {
        category: null,
        id: 0,
        lastInstanceValidation: null,
        changeDate: null,
        location: null,
        siteId: null,
        status: null,
        title: null,
        visible: false,
        frequency: new FrequencyPolicy(),
        extendedFrequencyPolicy: new ExtendedFrequencyPolicy(),
      }
    );
  }

  public create(template: TemplateFull): Observable<Id> {
    return super.postBis<TemplateFull, Id>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.create}`,
      new HttpParams(),
      // this.getTemplateFullItemClazz(),
      this.getIdClazz(),

      template
    );
  }

  public deleteTemplate(template: TemplateLight) {
    return super.delete<TemplateLight>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.save}${template.id}`,
      new HttpParams()
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  public exportPdf(id: string) {
    let params = new HttpParams();
    const site = this.siteService.getCurrentSite();
    if (site) {
      params = params.append('id', id);
    }
    params = params.append('isTemplate', true);
    return this.http.get(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.ronde.pdf}`,
      {
        observe: 'response',
        params: params,
      }
    );
  }

  public getFullTemplate(id: number): Observable<TemplateFull> {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('id', id);

    return super.get<TemplateFull>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.get}${id}`,
      httpParams,
      this.getTemplateFullItemClazz()
    );
  }

  public getGmpPdf(ids: IdNumberList): Observable<PresignedUrl> {
    return super.postBis<IdNumberList, PresignedUrl>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.pdf}`,
      new HttpParams(),
      // this.getTemplateFullItemClazz(),
      this.getPresignedUrlClazz(),
      ids
    );
  }

  public getTemplate(): Observable<TemplateItems> {
    let httpParams = new HttpParams();
    if (this.siteService.getCurrentSite()) {
      const site = this.siteService.getCurrentSite();
      if (site) {
        httpParams = httpParams.set('siteId', site.id);
      }
    }
    return super.get<TemplateItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.list}`,
      httpParams,
      this.getTemplateLightItemClazz()
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public save(template: TemplateFull): Observable<TemplateFullAttributes> {
    return super.putBis<TemplateFull, TemplateFullAttributes>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.save}${template.id}`,
      new HttpParams(),
      this.getTemplateFullAttributesClazz(),
      template
    );
  }

  protected getIdClazz(): typeof Id {
    return Id;
  }

  protected getPresignedUrlClazz(): typeof PresignedUrl {
    return PresignedUrl;
  }

  protected getTemplateFullAttributesClazz(): typeof TemplateFullAttributes {
    return TemplateFullAttributes;
  }

  protected getTemplateFullItemClazz(): typeof TemplateFull {
    return TemplateFull;
  }

  protected getTemplateLightItemClazz(): typeof TemplateItems {
    return TemplateItems;
  }
}
