import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { Days } from '../days.model';
import { ReferentielService } from '@al/referentiel';

@JsonConverter
export class DayConverter implements JsonCustomConvert<Days | null> {
  public deserialize(id: number): Days | null {
    const referentielService = new ReferentielService();

    return referentielService.getDaysFromId(id);
  }

  public serialize(days: Days): number | null {
    if (days) {
      return days.id;
    }
    return null;
  }
}

@JsonConverter
export class DaysConverter implements JsonCustomConvert<Array<Days> | null> {
  public deserialize(ids: number[]): Days[] | null {
    const referentielService = new ReferentielService();

    let days: Days[] = [];

    ids.forEach((id) => {
      if (referentielService.getDaysFromId(id)) {
        days.push(referentielService.getDaysFromId(id)!);
      }
    });
    return days;
  }

  public serialize(days: Days[]): number[] | null {
    if (days) {
      let ids: number[] = [];
      days.forEach((day) => {
        ids.push(day.id!);
      });
      return ids;
    }
    return null;
  }
}
