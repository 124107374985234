import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { ExtendedFrequencyType } from '@al/model';

@JsonConverter
export class ExtendedFrequencyTypeConverter
  implements JsonCustomConvert<ExtendedFrequencyType | null>
{
  public deserialize(id: string): ExtendedFrequencyType | null {
    if (id === ExtendedFrequencyType.NONE) {
      return ExtendedFrequencyType.NONE;
    } else if (id === ExtendedFrequencyType.HOURLY) {
      return ExtendedFrequencyType.HOURLY;
    } else if (id === ExtendedFrequencyType.WEEKLY) {
      return ExtendedFrequencyType.WEEKLY;
    } else if (id === ExtendedFrequencyType.DAILY) {
      return ExtendedFrequencyType.DAILY;
    } else if (id === ExtendedFrequencyType.MONTHLY_DAY) {
      return ExtendedFrequencyType.MONTHLY_DAY;
    } else if (id === ExtendedFrequencyType.MONTHLY_DATE) {
      return ExtendedFrequencyType.MONTHLY_DATE;
    } else if (id === ExtendedFrequencyType.ONE_SHOT) {
      return ExtendedFrequencyType.ONE_SHOT;
    } else if (id === ExtendedFrequencyType.EVERY_X_DAYS) {
      return ExtendedFrequencyType.EVERY_X_DAYS;
    } else if (id === ExtendedFrequencyType.EVERY_X_MONTHS) {
      return ExtendedFrequencyType.EVERY_X_MONTHS;
    } else if (id === ExtendedFrequencyType.EVERY_X_WEEKS) {
      return ExtendedFrequencyType.EVERY_X_WEEKS;
    }
    return null;
  }

  // );
  //   FrequencyType
  // .
  //
  //   forEach(
  //
  // (
  //   value: FrequencyType
  // ) => {
  //   if(value
  //
  // .
  //
  //   valueOf()
  //
  // ===
  //   id
  // ) {
  //   return
  //   value;
  // }
  // })
  //
  // return null;
  // }

  public serialize(frequencyType: ExtendedFrequencyType): string | null {
    if (frequencyType) {
      return frequencyType.valueOf();
    }
    return null;
  }
}
