import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefaultPath } from '@al/angular-route';
import { GenericComponent } from './generic.component';
import { GenericService } from '@al/services';
import { MatDialog } from '@angular/material/dialog';
import { ModelAbstract } from '@al/model';
import { NavigationService } from '@al/navigation';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-generic-comp',
  template: '',
  styleUrls: [],
})
export abstract class GenericFormComponent<T extends ModelAbstract>
  extends GenericComponent
  implements OnDestroy
{
  @ViewChild('cancelDialog')
  public cancelDialog!: TemplateRef<any>;

  @ViewChild('infoDialog')
  public infoDialog!: TemplateRef<any>;

  public creation = false;

  public form: FormGroup;

  public infoDialogMessage: string = '';

  public infoDialogTitle: string = '';

  public model: T;

  public constructor(
    protected dialog: MatDialog,
    protected navigationservice: NavigationService,
    protected formBuilder: FormBuilder,
    protected genericService: GenericService<T>,
    protected translateService: TranslateService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    super();
    this.creation = route.snapshot.url
      .join('/')
      .endsWith(new DefaultPath().CREATE);
    this.model = this.initModel();
    this.form = this.buildFormGroup(this.model);
  }

  protected back(): void {
    this.router.navigate([new DefaultPath().LIST], {
      relativeTo: this.route.parent,
    });
  }

  protected cancel(): void {
    this.openDialogWithRef(this.cancelDialog);
  }

  protected clean(): void {
    this.initFormGroup(this.model);
  }

  protected next(): void {
    this.genericService.activeNext();
  }

  protected openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  protected previous(): void {
    this.genericService.activePrevious();
  }

  protected setDialogTextValue(key: string): void {
    this.translateService
      .get(key)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: string) => {
        this.infoDialogMessage = value;
      });
  }

  protected setDialogTitle(key: string): void {
    this.translateService
      .get(key)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: string) => {
        this.infoDialogTitle = value;
      });
  }

  protected setFormValue(
    controlName: string,
    value: boolean | number | string | Date | null | undefined | object,
  ): void {
    // protected setFormValue(controlName: string, value: any): void {
    this.form.get(controlName)?.setValue(value);
  }

  public abstract buildFormGroup(entity: T | null): FormGroup;

  public abstract getEntityToSave(): T;

  public abstract initFormGroup(entity: T): void;

  public abstract initModel(): T;

  public abstract save(): void;
}
