import { JsonObject, JsonProperty } from 'json2typescript';
import { Owner } from './template/owner.model';
import { SMR } from './template/smr.model';
import { TemplateLight } from './template-light.model';
import { Widget } from './widget/widget.model';
import { WidgetArrayConverter } from './converter/widget-array.converter';

@JsonObject('TemplateFull')
export class TemplateFull extends TemplateLight {
  // @JsonProperty('frequencyPolicy', FrequencyPolicy, true)
  // public frequency: FrequencyPolicy | null = new FrequencyPolicy();

  @JsonProperty('lastWidgetId', Number, true)
  public lastWidgetId: number | null = null;

  @JsonProperty('owner', Owner, true)
  public owner: Owner | null = null;

  @JsonProperty('smr', SMR, true)
  public smr: SMR | null = null;

  @JsonProperty('templateBody', WidgetArrayConverter)
  public templateBody: Array<Widget> = new Array<Widget>();
}

@JsonObject('TemplateFullAttributes')
export class TemplateFullAttributes {
  @JsonProperty('Attributes', TemplateFull, true)
  public attributes: TemplateFull = new TemplateFull();
}

@JsonObject('Id')
export class Id {
  @JsonProperty('id', String, true)
  public id: string = '';
}
