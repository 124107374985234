import { Component, OnInit } from '@angular/core';
import {
  ExtendedFrequencyPolicy,
  ExtendedFrequencyType,
  TemplateFull,
} from '@al/model';
import { GenericComponent } from '@al/generic-components';
import { LocalStorageKey } from '@al/local-cache';
import { MatDialog } from '@angular/material/dialog';
import { TemplateCronDetailsComponent } from 'projects/template-components/src/lib/template-cron/template-cron-details/template-cron-details.component';
import { TemplateModelService } from '@al/dnd-service';
import moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-cron',
  templateUrl: './template-cron.component.html',
  styleUrls: ['./template-cron.component.css'],
})
export class TemplateCronComponent extends GenericComponent implements OnInit {
  public extendedFrequencyType = ExtendedFrequencyType;

  public frequency: ExtendedFrequencyPolicy = new ExtendedFrequencyPolicy();

  public templateFull: TemplateFull = new TemplateFull();

  public constructor(
    protected dialog: MatDialog, // protected override navigationservice: NavigationService, // protected override formBuilder: FormBuilder, // protected override genericService: GenericService<TemplateFull>,
    protected templateModelService: TemplateModelService, // protected override translateService: TranslateService, // protected override router: Router, // protected override route: ActivatedRoute
  ) {
    super();
    // dialog,
    // navigationservice,
    // formBuilder,
    // genericService,
    // translateService,
    // router,
    // route
  }

  // public buildFormGroup(entity: TemplateFull | null): FormGroup<any> {
  //   throw new Error('Method not implemented.');
  // }
  //
  // public getEntityToSave(): TemplateFull {
  //   throw new Error('Method not implemented.');
  // }
  //
  // public initFormGroup(entity: TemplateFull): void {
  //   throw new Error('Method not implemented.');
  // }
  //
  // public initModel(): TemplateFull {
  //   throw new Error('Method not implemented.');
  // }
  //
  // public save(): void {
  //   throw new Error('Method not implemented.');
  public getDate(startDate: Date | null) {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    let defaultDateFormat = localStorage.getItem(
      LocalStorageKey.DEFAULT_DATEFORMAT,
    );
    if (startDate) {
      return moment(startDate.getTime())
        .tz(timezone!)
        .format(defaultDateFormat!)
        .replace('00:00:00', '');
    }
    return '';
  }

  public getDateWithHour(startDate: Date | null) {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    let defaultDateFormat = localStorage.getItem(
      LocalStorageKey.DEFAULT_DATEFORMAT,
    );
    if (startDate) {
      return moment(new Date(startDate).getTime())
        .tz(timezone!)
        .format(defaultDateFormat!);
    }
    return '';
  }

  public ngOnInit(): void {
    this.templateModelService
      .getTemplateFullObservable()
      // .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templateFull: TemplateFull) => {
        if (templateFull.frequency) {
          this.templateFull = templateFull;
          if (!templateFull.extendedFrequencyPolicy) {
            templateFull.extendedFrequencyPolicy =
              new ExtendedFrequencyPolicy();
          }

          this.frequency = templateFull.extendedFrequencyPolicy
            ? templateFull.extendedFrequencyPolicy!
            : new ExtendedFrequencyPolicy();
        }
      });
  }

  public openFrequency() {
    // this.dialog.open(TemplateCronDetailsComponent,{width: 75px});
    this.dialog.open(TemplateCronDetailsComponent, {
      width: '30%',
    });
  }

  // }
}
