import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  Category,
  ComponentType,
  Days,
  ExtendedFrequencyPolicy,
  FrequencyOccurence,
  FrequencyType,
  FurnaceFull,
  MeasureWidget,
  RondeFull,
  SMR,
  SectionWidget,
  TemplateFull,
  TemplateLight,
  Validation,
  Widget,
} from '@al/model';
import {
  FurnaceFullService,
  FurnaceLightService,
  GateService,
  SiteService,
  TemplateFullService,
  TemplateLightService,
} from '@al/services';
import { Injectable, OnDestroy } from '@angular/core';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AlSpinnerService } from '@al/spinner';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { LocalStorageKey } from '@al/local-cache';
import { ReferentielService } from '@al/referentiel';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TemplateModelService implements OnDestroy {
  public template: TemplateFull = new TemplateFull();

  public templateBody: Widget[] = [];

  public templateBodySubject: BehaviorSubject<Widget[]> = new BehaviorSubject<
    Widget[]
  >([]);

  public templateSubject: BehaviorSubject<TemplateFull | null> =
    new BehaviorSubject<TemplateFull | null>(null);

  private ngUnsubscribe = new Subject();

  private supervisionValidation: Validation | null = null;

  public constructor(
    protected alSpinnerService: AlSpinnerService,
    protected furnaceLightService: FurnaceLightService,
    protected furnaceFullService: FurnaceFullService,
    protected gateService: GateService,
    protected siteService: SiteService,
    protected templateLightService: TemplateLightService,
    protected templateFullService: TemplateFullService,
  ) {}

  public clearModel(): void {
    this.templateSubject.next(null);
  }

  public createTemplate(spinnerUuid: string): void {
    this.template = new TemplateFull();

    if (this.siteService.getCurrentSite()) {
      this.template.siteId = this.siteService.getCurrentSite()!.id;
    }
    //standard template
    if (!this.furnaceLightService.getActiveElement()) {
      this.notify();
      this.notifyWidget();
      this.alSpinnerService.stopDataProcess(spinnerUuid);
    } else {
      //pycoso template
      this.furnaceFullService
        .getFullFurnace(this.furnaceLightService.getActiveElement()!!)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((furnace: FurnaceFull) => {
          let referntielService = new ReferentielService();
          let categories: Category[] = referntielService.categories.filter(
            (value: Category) => value.id == '7',
          );
          if (categories.length > 0) {
            this.template.category = categories[0];
          }
          this.template.smr = this.createSmr(furnace);
          this.furnaceLightService.clearActive();
          this.notify();
          this.notifyWidget();
          this.alSpinnerService.stopDataProcess(spinnerUuid);
        });
    }
  }

  public delete(widgetToDelete: Widget): void {
    this.template.templateBody.forEach((widget: Widget) => {
      if (widget.componentType === ComponentType.SECTION) {
        let section = widget as SectionWidget;
        section.children = this.removeFilter(widgetToDelete, section.children);
      }
    });

    this.template.templateBody = this.removeFilter(
      widgetToDelete,
      this.template.templateBody,
    );
    this.templateBodySubject.next(this.template.templateBody);
  }

  public getLightModel(): TemplateLight {
    return this.template as TemplateLight;
  }

  public getModel(): TemplateFull {
    return this.template;
  }

  public getTemplateBodyObservable(): Observable<Widget[]> {
    return this.templateBodySubject.asObservable();
  }

  public getTemplateFullObservable(): Observable<TemplateFull> {
    return this.templateSubject.asObservable().pipe(
      filter((template: TemplateFull | null) => template !== null),
      map((event: TemplateFull | null) => {
        return event!!;
      }),
    );
  }

  public initTemplate(creation: boolean, spinnerUuid: string): void {
    if (creation) {
      this.createTemplate(spinnerUuid);
    } else {
      this.templateLightService
        .getActive()
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter(
            (tempalteLight: TemplateLight | undefined) =>
              tempalteLight !== undefined,
          ),
          take(1),
          map((tempalteLight: TemplateLight | undefined) => {
            return tempalteLight!!;
          }),
        )
        .subscribe((template: TemplateLight) => {
          if (template) {
            this.templateFullService
              .getFullTemplate(template)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((templateFull: TemplateFull) => {
                this.template = templateFull;
                this.templateBody = this.template.templateBody;
                if (this.template.smr) {
                  this.furnaceFullService
                    .getByKey(this.template.smr.id)
                    .subscribe((furnace: FurnaceFull) => {
                      this.notify();
                      this.notifyWidget();
                      this.gateService.reserveFromTemplate(
                        furnace,
                        this.template,
                      );
                      this.alSpinnerService.stopDataProcess(spinnerUuid);
                    });
                } else {
                  this.notify();
                  this.notifyWidget();
                  this.alSpinnerService.stopDataProcess(spinnerUuid);
                }
              });
          }
        });
    }
  }

  public insertWidget(
    widget: Widget,
    sectionId: string | null,
    index: number,
  ): void {
    if (sectionId) {
      this.template.templateBody.forEach((child: Widget) => {
        if (
          child.id !== null &&
          child.id === sectionId &&
          child.componentType === ComponentType.SECTION
        ) {
          let sectionWidget = child as SectionWidget;
          sectionWidget.children.splice(index, 0, widget);
        }
      });
    } else {
      this.template.templateBody.splice(index, 0, widget);
      // this.notify();
    }
    this.templateBodySubject.next(this.template.templateBody);
  }

  public moveItemInArray(
    event: CdkDragDrop<Widget[]>,
    sectionId: string | null = null,
  ): void {
    if (sectionId) {
      const sectionWidget: SectionWidget = this.template.templateBody.filter(
        (child: Widget) => child.id === sectionId,
      )[0] as SectionWidget;
      this.arraymove(
        sectionWidget.children,
        event.previousIndex,
        event.currentIndex,
      );
      this.templateBodySubject.next(this.template.templateBody);
    } else {
      this.arraymove(
        this.template.templateBody,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  public moveWidget(
    widget: Widget,
    sectionId: string | null,
    index: number,
  ): void {
    //clean the reference

    this.template.templateBody = this.template.templateBody.filter(
      (child: Widget) => {
        return child.id !== widget.id;
      },
    );

    this.template.templateBody
      .filter((child: Widget) => child.componentType === ComponentType.SECTION)
      .forEach((section: Widget) => {
        let sectionWidget = section as SectionWidget;
        sectionWidget.children = sectionWidget.children.filter(
          (child: Widget) => {
            return child.id !== widget.id;
          },
        );
      });

    if (sectionId) {
      this.template.templateBody.forEach((child: Widget) => {
        if (
          child.id === sectionId &&
          child.componentType === ComponentType.SECTION
        ) {
          let sectionWidget = child as SectionWidget;
          widget.id =
            sectionWidget.id?.split('_')[1] + '_' + widget.id?.split('_')[1];
          sectionWidget.children.splice(index, 0, widget);
          // this.notify();
        }
      });
    } else {
      widget.id = '0_' + widget.id?.split('_')[1];
      this.template.templateBody.splice(index, 0, widget);
      // this.notify();
    }
    this.templateBodySubject.next(this.template.templateBody);
  }

  public nextId(): string {
    if (this.template.lastWidgetId !== null) {
      this.template.lastWidgetId = this.template.lastWidgetId + 1;
      return this.template.lastWidgetId.toString();
    } else {
      this.template.lastWidgetId = 1;
    }
    return this.template.lastWidgetId.toString();
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public notify(): void {
    this.templateSubject.next(this.template);
  }

  public notifyWidget(): void {
    this.templateBodySubject.next(this.template.templateBody);
  }

  public setAllMandatory(id: string | null, trueFalse: boolean): void {
    var sections = this.template.templateBody.filter(
      (value: Widget) => value.id === id,
    );

    if (sections.length === 1) {
      var section = sections[0] as SectionWidget;
      section.children.forEach((child: Widget) => {
        child.isMandatory = trueFalse;
      });
    }
  }

  public setAndGetStartTime(value: string): Date {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (timezone) {
      let v = moment.tz(value, timezone);
      if (this.template.frequency) {
        this.template.frequency.startTime = v.toDate();

        return this.template.frequency.startTime;
      }
    }
    return new Date();
  }

  public setCategory(category: Category): void {
    this.template.category = category;
  }

  public setDayOfTheWeek(dayOfTheWeek: Days): void {
    if (this.template.frequency) {
      this.template.frequency.dayOfTheWeek = dayOfTheWeek;
    }
  }

  public setEnableAllThreshold(id: string | null, trueFalse: boolean): void {
    var sections = this.template.templateBody.filter(
      (value: Widget) => value.id === id,
    );

    if (sections.length === 1) {
      var section = sections[0] as SectionWidget;
      section.children
        .filter(
          (child: Widget) =>
            child.componentType === ComponentType.MEASURE ||
            child.componentType === ComponentType.WHEEL_PICKER,
        )
        .forEach((child: Widget) => {
          if (child.componentType === ComponentType.MEASURE) {
            (child as MeasureWidget).disableControl = trueFalse;
          }
          if (child.componentType === ComponentType.WHEEL_PICKER) {
            (child as MeasureWidget).disableControl = trueFalse;
          }
        });
    }
  }

  public setFrequencyType(value: FrequencyType): void {
    if (this.template.frequency) {
      this.template.frequency.type = value;
    }
  }

  public setLocation(location: string): void {
    this.template.location = location;
  }

  public setOccurence(occurence: FrequencyOccurence): void {
    if (this.template.frequency) {
      this.template.frequency.occurence = occurence;
    }
  }

  public setPeriod(period: number): void {
    if (this.template.frequency) {
      this.template.frequency.period = period * 60 * 60 * 1000;
    }
  }

  public setSupervisionValidation(ronde: RondeFull): void {
    this.supervisionValidation = ronde.instance?.supervisorValidation!;
  }

  public setTitle(title: string): void {
    this.template.title = title;
  }

  public setVisibility(visible: boolean): void {
    this.template.visible = visible;
  }

  public updateFrequency(frequency: ExtendedFrequencyPolicy | null) {
    this.template.extendedFrequencyPolicy = frequency;
  }

  public updateId(id: number): void {
    this.template.id = id;
  }

  private arraymove(arr: Widget[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  private createSmr(furnace: FurnaceFull): SMR {
    const smr = new SMR();
    smr.id = furnace.id;
    smr.maxTemperatureCelsius = furnace.MOT;
    smr.minMeasureSequenceCount = furnace.minMeasureSequenceCount;
    smr.minTemperatureCelsius = furnace.minOT;
    smr.name = furnace.name;
    smr.scadaPrefix = furnace.scadaPrefix;
    smr.scadaServer = furnace.scadaServer;
    smr.unstableDeltaTemperature = furnace.unstableDeltaTemperature;
    smr.unstableRetryPolicyCount = furnace.unstableRetryPolicyCount;
    smr.veryHotTemperature = furnace.VHT;
    return smr;
  }

  private removeFilter(widget: Widget, widgetList: Widget[]): Widget[] {
    return widgetList.filter((child: Widget) => {
      return child.id !== widget.id;
    });
  }
}
