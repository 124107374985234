export enum FrequencyFormNameEnum {
  TYPE = 'TYPE',
  INTERVAL = 'INTERVAL',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  START_EXECUTION_TIME = 'START_EXECUTION_TIME',
  ONE_SHOT_EXECUTION_TIME = 'ONE_SHOT_EXECUTION_TIME',
  END_EXECUTION_TIME = 'END_EXECUTION_TIME',
  // MONDAY = 'MONDAY',
  // TUESDAY = 'TUESDAY',
  // WEDNESDAY = 'WEDNESDAY',
  // THURSDAY = 'THURSDAY',
  // FRIDAY = 'FRIDAY',
  // SATURDAY = 'SATURDAY',
  // SUNDAY = 'SUNDAY',
  APPLICABLE_DAYS = 'APPLICABLE_DAYS',
  EXECUTION_TIME = 'EXECUTION_TIME',
  USE_DATE_FILTER = 'USE_DATE_FILTER',
  USE_TIME_FILTER = 'USE_TIME_FILTER',
  USE_DAY_FILTER = 'USE_DAY_FILTER',
  OCCURENCY = 'OCCURENCY',
  DAY = 'DAY',
}
